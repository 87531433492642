import { template as template_0d33d241923b4115abee5a13f9b3d7c6 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import AceEditor from "discourse/components/ace-editor";
import { escapeExpression } from "discourse/lib/utilities";
export default class FKControlCode extends Component {
    static controlType = "code";
    initialValue = this.args.field.value || "";
    @action
    handleInput(content) {
        this.args.field.set(content);
    }
    get style() {
        if (!this.args.height) {
            return;
        }
        return htmlSafe(`height: ${escapeExpression(this.args.height)}px`);
    }
    static{
        template_0d33d241923b4115abee5a13f9b3d7c6(`
    <AceEditor
      @content={{this.initialValue}}
      @onChange={{this.handleInput}}
      @mode={{@lang}}
      @disabled={{@field.disabled}}
      class="form-kit__control-code"
      style={{this.style}}
      ...attributes
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
