import { template as template_a21e769a6a4d46b8969c60439a0be5fc } from "@ember/template-compiler";
const FKLabel = template_a21e769a6a4d46b8969c60439a0be5fc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
