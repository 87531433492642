import { template as template_48e8395d9e314246a0cc1e968dc7aebe } from "@ember/template-compiler";
const SidebarSectionMessage = template_48e8395d9e314246a0cc1e968dc7aebe(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
